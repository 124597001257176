import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "px-10 my-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopdSetup = _resolveComponent("CopdSetup")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_WorkflowLayout = _resolveComponent("WorkflowLayout")!

  return (_openBlock(), _createBlock(_component_WorkflowLayout, {
    name: _ctx.$t('custom.uhb.copd.name'),
    steps: _ctx.steps,
    "current-step": _ctx.step,
    patient: _ctx.patient,
    onCancel: _ctx.cancel
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_BaseButton, {
        size: "large",
        color: "ghost",
        type: "button",
        onClick: _ctx.back
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.back')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_BaseButton, {
        size: "large",
        color: "success",
        class: "ml-2",
        type: "submit",
        onClick: _ctx.complete
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.complete')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      (_ctx.program)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            (_ctx.step === 0)
              ? (_openBlock(), _createBlock(_component_CopdSetup, {
                  key: 0,
                  program: _ctx.program,
                  errors: _ctx.errors ? _ctx.errors : null,
                  onUpdateProgram: _ctx.updateProgram
                }, null, 8, ["program", "errors", "onUpdateProgram"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["name", "steps", "current-step", "patient", "onCancel"]))
}