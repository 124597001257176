
import { Vue, Options } from 'vue-class-component';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import { BaseButton, CopdSetup } from '@/lib/components';
import { CopdProgramService } from '@/services/api';
import axios, { CancelTokenSource } from 'axios';
import { CopdProgramRequest, CopdProgramSetting, Patient } from '@/models';
import { IValidationError } from '@/lib';
import { Pathways, recordUserEvent } from '@/helpers/aws.helper';

@Options({
  components: { WorkflowLayout, BaseButton, CopdSetup },
  props: {
    patient: {
      type: Object,
      required: true
    }
  }
})
export default class CopdConfigPage extends Vue {
  patient!: Patient;
  copdProgramService = new CopdProgramService();
  request: CancelTokenSource | null = null;
  copdSettings: CopdProgramSetting | null = null;
  program: CopdProgramRequest = {
    settings: {
      spo2_range_low: null,
      spo2_range_high: null,
      method_of_oxygen_delivery: {
        code: '',
        label: ''
      }
    },
    latest_consult: null
  };

  errors: IValidationError | null = null;

  get steps(): string[] {
    return [this.$t('custom.uhb.copd.patient-req') as string];
  }

  get step() {
    // We want the URL param to be 1-based, but the value in the component to be zero-based
    return Number(this.$route.query.step || 1) - 1;
  }

  mounted() {
    this.recordEvent('started adding patient to virtual ward');
  }

  updateProgram(newProgram: CopdProgramRequest) {
    this.program = newProgram;
  }

  recordEvent(event: string) {
    recordUserEvent(event, Pathways.COPD, this.patient.id);
  }

  cancel() {
    this.$router.push({ name: 'patient-details' });
  }

  back() {
    if (this.step === 0) {
      this.cancel();
      return;
    }
    this.clearErrors();

    // @ts-ignore
    this.$router.push({
      ...this.$route,
      query: {
        ...this.$route.query,
        step: String(this.step) // Add 2 because the URL param is 1-based
      }
    });
  }

  clearErrors() {
    this.errors = null;
  }

  async complete() {
    if (this.program) {
      try {
        this.request = axios.CancelToken.source();

        const response: any = await this.copdProgramService.createForPatient(this.patient.id, this.program, {
          cancelToken: this.request.token
        });

        // this ensures that the navigation shows the virtual ward tab on
        // the very next route, without the need to re-fetch the patient
        this.patient.copd_home_monitoring_program = {
          id: response.data.id,
          discharged_program_review_id: null
        };
        this.recordEvent('completed adding patient to virtual ward');
        this.$router.push({
          name: 'patient-virtual-ward',
          params: { patientId: this.patient.id, programId: response.data.id }
        });
        this.request = null;
        this.errors = null;
      } catch (e) {
        if (e.response && e.response.status === 422) {
          this.errors = e.response.data.errors;
        } else {
          this.errors = {
            message: e.response.data.message,
            errors: {}
          };
        }
      }
    }
  }
}
